import React, { useEffect, useState } from "react";
import i18n from "../../i18n";
import { useLocation } from "react-router-dom";
import ComponentType from "../../models/ComponentType";
import { MainTeaserProps } from "../../models/MainTeaserProps";
import { InlineVideoProps } from "../../models/InlineVideoProps";
import { FullWidthImageProps } from "../../models/FullWidthImageProps";
import { LegalDocumentProps } from "../../models/LegalDocumentProps";
import { FAQProps } from "../../models/FAQProps";
import { TestimonialCarouselProps } from "../../models/TestimonialCarouselProps";
import { ContactFormProps } from "../../models/ContactFormProps";
import { NewsletterFormProps } from "../../models/NewsletterFormProps";
import { ServicesCarouselProps } from "../../models/ServicesCarouselProps";
import { ServicesTeaserProps } from "../../models/ServicesTeaserProps";
import MainTeaser from "../welcome/MainTeaser";
import InlineVideo from "../video/InlineVideo";
import FAQs from "../faqs/FAQs";
import TestimonialCarousel from "../carousel/testimonial/TestimonialCarousel";
import ContactForm from "../form/ContactForm";
import InfoTeaser from "../info-teaser/InfoTeaser";
import ServicesCarousel from "../carousel/services/ServicesCarousel";
import { MotionConfig, motion } from "framer-motion";
import NewsletterForm from "../newsletter/NewsletterForm";
import LegalDocument from "../legal/LegalDocument";
import FullWidthImage from "../full-width-image/FullWidthImage";
import TagManager from "react-gtm-module";
import { Page } from "../../App";
import Confirmation from "../confirmation/Confirmation";

type DataType =
  | MainTeaserProps
  | InlineVideoProps
  | FullWidthImageProps
  | LegalDocumentProps
  | FAQProps
  | TestimonialCarouselProps
  | ContactFormProps
  | NewsletterFormProps
  | ServicesCarouselProps
  | ServicesTeaserProps
  | LegalDocumentProps;

type APIResponse = DataType[];

export enum PageType {
  Home,
  About,
  Blog,
  Contact,
  ColourAnalysis,
  StyleDiscovery,
  WardrobeEdit,
  PersonalShopping,
  StyleMakeover,
  TermsAndConditions,
  PrivacyPolicy,
  NotFound
}

export interface PageProps {
  pageType: PageType;
  bookAction: () => void;
}

const PageContent: React.FC<PageProps> = ({ pageType, bookAction }) => {
  const location = useLocation();
  const [pageData, setContent] = useState<APIResponse | null>(null);

  useEffect(() => {
    const lang = location.pathname.startsWith("/pt") ? "pt" : "en";
    i18n.changeLanguage(lang);
    loadPage();
  }, [location.pathname, i18n]);

  const endpoint = () => {
    const baseUrl = window.location.origin.replace(":3000", ":8080");
    const lang = location.pathname.startsWith("/pt") ? "pt" : "en";
    switch (pageType) {
      case PageType.Home:
        return baseUrl + `/api/${lang}/home`;
      case PageType.About:
        return baseUrl + `/api/${lang}/about`;
      case PageType.Contact:
        return baseUrl + `/api/${lang}/contact`;
      case PageType.ColourAnalysis:
        return baseUrl + `/api/${lang}/colour-analysis`;
      case PageType.StyleDiscovery:
        return baseUrl + `/api/${lang}/style-discovery`;
      case PageType.WardrobeEdit:
        return baseUrl + `/api/${lang}/wardrobe-edit`;
      case PageType.PersonalShopping:
        return baseUrl + `/api/${lang}/personal-shopping`;
      case PageType.StyleMakeover:
        return baseUrl + `/api/${lang}/style-makeover`;
      case PageType.TermsAndConditions:
        return baseUrl + `/api/${lang}/terms-and-conditions`;
      case PageType.PrivacyPolicy:
        return baseUrl + `/api/${lang}/privacy-policy`;
      default:
        return "";
    }
  };

  function loadPage() {
    fetch(endpoint())
      .then((response) => response.json())
      .then((json: APIResponse) => setContent(json));
  }

  const animation = {
    hidden: { opacity: 0, },
    show: { opacity: 1 }
  }
  
  const renderContent = () => {
    return (
      <MotionConfig transition={{ duration: 0.6, delay: 0.5 }}>
        <motion.div
          style={{ minHeight: '90vh', paddingTop: pageType != PageType.Home ? '6rem' : '0rem' }}
          variants={animation}
          initial="hidden"
          animate="show">
        {
          pageData
            ?.sort((a, b) => a.position - b.position)
            .map((item, index) => {
              switch (item.type) {
                case ComponentType.MainTeaser:
                  return <MainTeaser key={index} {...(item as MainTeaserProps)} bookAction={bookAction} />;
                case ComponentType.InlineVideo:
                  return <InlineVideo key={index} {...(item as InlineVideoProps)} bookAction={bookAction} />;
                case ComponentType.FullWidthImage:
                  return (
                    <FullWidthImage key={index}  {...(item as FullWidthImageProps)}/>
                  );
                case ComponentType.LegalDocument:
                  return <LegalDocument key={index} {...(item as LegalDocumentProps)} />
                case ComponentType.FAQs:
                  return <FAQs key={index} {...(item as FAQProps)} />;
                case ComponentType.TestimonialCarousel:
                  return (
                    <TestimonialCarousel key={index} {...(item as TestimonialCarouselProps)} />
                  );
                case ComponentType.ContactForm:
                  return <ContactForm key={index} {...(item as ContactFormProps)} />;
                case ComponentType.NewsletterForm:
                  return <NewsletterForm key={index} {...(item as NewsletterFormProps)} />;
                case ComponentType.ServicesCarousel:
                  return <ServicesCarousel key={index} {...(item as ServicesCarouselProps)} />;
                case ComponentType.ServicesTeaser:
                  return <InfoTeaser key={index} {...(item as ServicesTeaserProps)} />;
                default:
                  return null;
              }
            })
        }
        </motion.div>
      </MotionConfig>
    );
  };

  return (
    <div>
      {renderContent()}
    </div>
  );
};

export default PageContent;