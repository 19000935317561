enum ComponentType {
    MainTeaser = "mainTeaser",
    InlineVideo = "inlineVideo",
    FullWidthImage = "fullWidthImage",
    LegalDocument = "legalDocument",
    FAQs = "faQs",
    TestimonialCarousel = "testimonialCarousel",
    ContactForm = "contactForm",
    NewsletterForm = "newsletterForm",
    ServicesCarousel = "servicesCarousel",
    ServicesTeaser = "servicesTeaser"
}

export default ComponentType;