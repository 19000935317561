import React, { Component, useMemo, useState } from "react";
import { Row, Col, Button, Layout, Menu, Dropdown, Tooltip } from "antd";
import MediaQuery from "react-responsive";
import Logo from "../logo/Logo";
import AnimatedLogo from "../logo/AnimatedLogo";
import Hamburger, { MenuState } from "../hamburger/Hamburger";
import MobileMenu from "../menu/MobileMenu";
import PrimaryButton from "../button/Button";
import { withTranslation, WithTranslation } from "react-i18next";
// Styling
import "../../theme/base.css";
import "./navigation.css";
import type { MenuProps } from "antd";
import { GlobalOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

interface NavigationOptions {
  mediumBlog: boolean;
}

type DataType = NavigationOptions;
type APIResponse = DataType[];

type NavigationProps = WithTranslation & {
  logoHref: string;
  menuItems: MenuProps["items"];
  bookOnClick: () => void;
};

interface LanguageSwitcherProps {
  color: string;
  opacity: string;
}

export const LanguageSwitcher = ({
  color,
  opacity,
}: LanguageSwitcherProps): React.ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const [arrow, setArrow] = useState("Show");
  const text = <span>Fala português?</span>;
  const mergedArrow = useMemo(() => {
    if (arrow === "Hide") {
      return false;
    }

    if (arrow === "Show") {
      return true;
    }

    return {
      pointAtCenter: true,
    };
  }, [arrow]);

  const english = () => {
    let newPath;
    if (location.pathname.startsWith("/pt/")) {
      // Currently in Portuguese. Switch to English
      newPath = location.pathname.replace("/pt/", "/");
      document.documentElement.lang = "en";
      navigate(newPath);
    }
  };

  const portuguese = () => {
    let newPath;

    if (!location.pathname.startsWith("/pt/")) {
      // Currently in Portuguese. Switch to English
      newPath = "/pt" + location.pathname;
      document.documentElement.lang = "pt";
      navigate(newPath);
    }
  };

  const languages: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <Button
          className="ms-language-switcher__button"
          title="English"
          type="text"
          onClick={() => english()}
          style={{ backgroundColor: "transparent", color: "#fff" }}
        >
          English
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          className="ms-language-switcher__button"
          title="Português"
          type="text"
          onClick={() => portuguese()}
          style={{ backgroundColor: "transparent", color: "#fff" }}
        >
          Português
        </Button>
      ),
    },
  ];

  return (
    <Dropdown
      menu={{ items: languages }}
      placement="bottom"
      arrow={{ pointAtCenter: true }}
      overlayStyle={{ textAlign: "center", width: "8.5rem" }}
    >
      <Tooltip
        placement="bottom"
        title={text}
        arrow={mergedArrow}
        defaultOpen={false}
        destroyTooltipOnHide={true}
        open={false}
      >
        <GlobalOutlined
          className="ms-language-switcher"
          style={{
            cursor: "pointer",
            color: color,
            fontSize: "18px",
            opacity: opacity,
          }}
        />
      </Tooltip>
    </Dropdown>
  );
};

class Navigation extends Component<NavigationProps, MenuState> {
  toggleState = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  };

  constructor(props: NavigationProps) {
    super(props);

    const { i18n } = this.props;

    this.state = {
      isOpen: false,
      toggleState: this.toggleState,
      currentLanguage: i18n.language,
      href: "/",
      mediumBlogActive: false
    };
  }

  componentDidMount(): void {
    const { i18n } = this.props;
    i18n.on("languageChanged", (lng: string) => {
      this.setState({ currentLanguage: lng });
    });

    const baseUrl = window.location.origin.replace(":3000", ":8080");
    fetch(baseUrl + '/api/medium')
        .then(response => response.json())
        .then(data => {
            // Assuming the API returns a boolean to indicate if the blog is active
            const isBlogActive = data.active;
            // Update the state
            this.setState({ mediumBlogActive: isBlogActive });
        })
        .catch(error => {
            console.error('Error fetching data:', error);
            // You might want to handle error or set a default state
        });
  }

  render() {
    const animation = {
      hidden: { opacity: 0 },
      show: { opacity: 1 },
    };
    return (
      <motion.div
        style={{ width: '100%' }}
        transition={{ type:'tween', duration: 0.2, delay: 0.5 }}
        variants={animation}
        initial="hidden"
        animate="show"
      >
        <nav className={`${this.state.mediumBlogActive ? "" : "ms-blog-disabled"}`}>
          <MediaQuery minWidth={992}>
            <Row justify="start" align="middle">
              <Col span={8} style={{ height: "1.5rem" }}>
                <Menu
                  theme="light"
                  mode="horizontal"
                  items={this.props.menuItems}
                  style={{ height: "1.5rem", lineHeight: "1.5rem" }}
                />
              </Col>
              <Col span={8}>
                <AnimatedLogo href={this.props.logoHref} />
              </Col>
              <Col span={8}>
                <Row justify="end" gutter={15} style={{ paddingRight: "1rem" }}>
                  <Col>
                    <PrimaryButton
                      large={false}
                      primaryText={`${t("navigation.cta.primary")}`}
                      secondaryText={`${t("navigation.cta.secondary")}`}
                      onClick={() => this.props.bookOnClick()}
                    />
                  </Col>
                  <Col>
                    <LanguageSwitcher opacity="1" color="#000" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </MediaQuery>
          <MediaQuery maxWidth={991}>
            <MobileMenu
              isOpen={this.state.isOpen}
              toggleState={this.toggleState}
              currentLanguage={this.state.currentLanguage}
              mediumBlogActive={this.state.mediumBlogActive}
              href="/"
            />
            <Row
              justify="space-between"
              align="middle"
              style={{ height: "4rem" }}
            >
              <Col>
                <Logo
                  isOpen={this.state.isOpen}
                  toggleState={this.toggleState}
                  currentLanguage={this.state.currentLanguage}
                  mediumBlogActive={this.state.mediumBlogActive}
                  href={this.props.logoHref}
                />
              </Col>
              <Col>
                <Row align="middle" style={{ height: "4rem" }} gutter={5}>
                  <Col>
                    <LanguageSwitcher
                      opacity={`${this.state.isOpen ? "0" : "1"}`}
                      color={`${this.state.isOpen ? "#fff" : "#000"}`}
                    />
                  </Col>
                  <Col>
                    <Hamburger
                      isOpen={this.state.isOpen}
                      toggleState={this.toggleState}
                      currentLanguage={this.state.currentLanguage}
                      mediumBlogActive={this.state.mediumBlogActive}
                      href="/"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </MediaQuery>
        </nav>
      </motion.div>
    );
  }
}

export default withTranslation()(Navigation);
