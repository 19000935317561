import React from 'react';
import { LegalDocumentProps } from '../../models/LegalDocumentProps';
import { Col, Layout, Row } from 'antd';
import Headline from '../headline/Headline';
import Text from '../text/Text';
import './legal.css';

const LegalDocument: React.FC<LegalDocumentProps> = ({
    title,
    document
}) => {

    function paragraphs() {
        const paragraphs: JSX.Element[] = document
        .split('<br>')
        .map((paragraph, index) => (
          <Text align='left' text={paragraph} key={index} />
        ));
        return paragraphs;
    }

    return (
        <Layout className='ms-legal ms-component ms-inner-content ms-content ms-bottom--spacer'>
            <Row>
                <Col>
                    <Headline title={title} subtitle='' level='h1' />
                </Col>
            </Row>
            <Row>
                <Col>
                    {paragraphs()}
                </Col>
            </Row>
        </Layout>
    );
}

export default LegalDocument;