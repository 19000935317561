import React, { Component } from 'react';
import {
    InstagramOutlined,
    LinkedinOutlined,
    WhatsAppOutlined,
    MediumOutlined
  } from "@ant-design/icons";
import pinterest from '../../assets/pinterest.svg';
import Icon from '../icon/Icon';

export enum SocialIconType {
    Instagram,
    WhatsApp,
    LinkedIn,
    Pinterest,
    Medium
}

interface SocialIconProps {
    type: SocialIconType;
    color: string;
    href: string;
    size: number;
    paddingTop?: string;
}

class SocialIcon extends Component<SocialIconProps> {

    render() {
        return (
            <a href={this.props.href} target='_blank' style={{paddingTop: this.props.paddingTop}}>{this.icon()}</a>
        );
    }

    icon(): JSX.Element {
        switch (this.props.type) {
            case SocialIconType.Instagram:
                return <InstagramOutlined style={{color: `${this.props.color}`, fontSize: `${this.props.size}px`}} />
            case SocialIconType.WhatsApp:
                return <WhatsAppOutlined style={{color: `${this.props.color}`, fontSize: `${this.props.size}px`}}/>
            case SocialIconType.LinkedIn:
                return <LinkedinOutlined style={{color: `${this.props.color}`, fontSize: `${this.props.size}px`}} />
            case SocialIconType.Medium:
                return <MediumOutlined style={{color: `${this.props.color}`, fontSize: `${this.props.size}px`}} />
            case SocialIconType.Pinterest:
                return <Icon className='ms-footer-icon' color='#fff' src={pinterest} title='Pinterest' height={this.props.size} />;
        }
    }

}

export default SocialIcon;