import React from "react";
import { Typography } from "antd";
// Styles
import "./text.css";

interface TextProps {
  align: "left" | "center" | "right" | "justify";
  text: string;
  color?: "white" | "black";
}

const Text: React.FC<TextProps> = ({ align, text, color = "black" }) => {
    const paragraphs = text.split("<br>").filter(p => p.trim() !== '').map((paragraph, index) => (
        <p key={index}>{paragraph}</p>
      ));

  return (
    <Typography.Paragraph
      className="ms-copy"
      copyable={false}
      style={{ textAlign: align, color: color }}
    >
      {paragraphs}
    </Typography.Paragraph>
  );
};

export default Text;
