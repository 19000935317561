import { Layout, Row, Col, Typography } from "antd";
import React from "react";
import Headline from "../../headline/Headline";
import './faqheader.css';

const { Title } = Typography;

interface FAQHeaderProps {
  primary: string;
  secondary: string;
}

const FAQHeader: React.FC<FAQHeaderProps> = ({ primary, secondary }) => {
  return (
    <Title level={2} className="ms-faq-header">
      <Layout className='ms-content'>
        <Row justify='start' align='bottom' gutter={[10, 0]}>
          <Col>
              <span className="ms-faq-first">FAQ</span>
              <span>'S</span>
          </Col>
          <Col>
              <span className="ms-faq-header__service__title">{primary}</span>
              <span className="ms-faq-header__service__title">{secondary}</span>
          </Col>
        </Row>
      </Layout>
    </Title>
  );
};

export default FAQHeader;
