import "./i18n";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { PopupModal } from "react-calendly";
import {
  Col,
  ConfigProvider,
  Dropdown,
  FloatButton,
  Form,
  Layout,
  MenuProps,
  Modal,
  Row,
  Space,
  message,
  notification,
} from "antd";
import { WhatsAppOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Content, Footer, Header } from "antd/es/layout/layout";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Outlet,
  Navigate,
} from "react-router-dom";
import Nav from "./components/navigation/Navigation";
import { useTranslation } from "react-i18next";
import PageContent, { PageType } from "./components/page/PageContent";
import FooterContent from "./components/footer/FooterContent";
import PrimaryButton from "./components/button/Button";
import { useMediaQuery } from "react-responsive";
import NotFound from "./components/error/NotFound";
import { Helmet } from "react-helmet-async";
import Headline from "./components/headline/Headline";
import "./components/modal/modal.css";
import FormField, { FormFieldType } from "./components/form/fields/FormField";
import Text from "./components/text/Text";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import axios from "axios";
import TagManager from "react-gtm-module";

interface PageProps {
  pageType: PageType;
}

export const Page: React.FC<PageProps> = ({ pageType }) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const key = `open${Date.now()}`;
  const [api, contextHolder] = notification.useNotification();
  const [success, setSuccess] = useState(false);
  const [leadModalOpen, setLeadModalOpen] = useState(false);
  const [sent, sentEmail] = useState(false);
  const [messageApi, messageContextHolder] = message.useMessage();

  // const sendMessage = () => {
  //   messageApi.open({
  //     key,
  //     type: "loading",
  //     content: t("messages.sending"),
  //     style: { color: "white" },
  //   });
  // };

  // const openMessage = () => {
  //   setTimeout(() => {
  //     messageApi.open({
  //       key,
  //       type: "warning",
  //       content: t('errors.newsletter'),
  //       duration: 5,
  //       style: { color: "white" },
  //     });
  //   }, 500);
  // };

  // const onSubmit = async (values: any) => {
  //   if (!executeRecaptcha) {
  //     return;
  //   }

  //   sendMessage()
  //   const token = await executeRecaptcha("NewsletterForm");

  //   const json = {
  //     token: token,
  //     email: values.email,
  //   };
  //   try {
  //     sentEmail(true);
  //     const baseUrl = window.location.origin.replace(":3000", ":8080");
  //     console.log(`Base Url: ${baseUrl}`);
  //     const response = await fetch(baseUrl + "/api/newsletter", {
  //       method: "POST",
  //       mode: 'cors',
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(json),
  //     });
  //     if (!response.ok) {
  //       sentEmail(false);
  //       openMessage();
  //       throw new Error(`HTTP error! Status: ${response.status}`);
  //     } else {
  //       setSuccess(true);
  //       setLeadModalOpen(false);
  //       // await downloadPdf();
  //       sentEmail(false);
  //     }
  //   } catch (error) {
  //     console.error("There was an error sending the form:", error);
  //   }
  // };

  // const downloadPdf = async () => {
  //   try {
  //     // Make a request to the backend to get the file
  //     const baseUrl = window.location.origin.replace(":3000", ":8080");
  //     const lang = location.pathname.startsWith("/pt") ? "pt" : "en";
  //     const response = await axios.get(baseUrl + `/api/${lang}/gift`, {
  //       responseType: 'blob', // important
  //     });

  //     // Create a new Blob object using the response data of the file
  //     const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

  //     // Create a link element, use it to download the blob, and remove it
  //     const downloadUrl = window.URL.createObjectURL(pdfBlob);
  //     const link = document.createElement('a');
  //     link.href = downloadUrl;
  //     link.setAttribute('download', lang == 'en' ? 'melinastylist-gift-en.pdf' : 'melinastylist-gift-pt.pdf'); // Any other extension can also be used
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //     Cookies.set("modalClosed", "true", { expires: 7 });
  //   } catch (error) {
  //     console.error('There was an error!', error);
  //   }
  // };

  const closeModal = () => {
    setLeadModalOpen(false);
  };

  // const [form] = Form.useForm();
  const leadModal = () => {
    return (
      <Modal
        wrapClassName="ms-modal"
        centered
        open={leadModalOpen}
        footer={null}
        onCancel={() => closeModal()}
        width={800}
        mask={true}
        style={{
          textAlign: "center",
          background: "#fff",
        }}
      >
        <Headline
          title={t('form.headline.primary')}
          subtitle={t('form.headline.secondary')}
          level="h4"
          align="center"
        />
        <br />
        <b>
          <Text
            align="center"
            text={t('form.text.primary')}
          />
        </b>
        <Text
          align="center"
          text={t('form.text.secondary')}
        />
          <Row justify='center'>
          <Col span={12}>
            <PrimaryButton
              large={true}
              primaryText={t('form.button.primary')}
              secondaryText={t('form.button.secondary')}
              onClick={() => {
                closeModal();
                setIsOpenSecondary(true);
                Cookies.set("modalClosed", "true", { expires: 7 });
              }}
              isSubmit={false}
              loading={sent}
            />
          </Col>
        </Row>
      </Modal>
    );
  };

  const agree = () => {
    api.destroy(key);
    Cookies.set("melinastylist.com", "accepted", { expires: 365 });
  };

  const root = document.getElementById("root") as HTMLDivElement;
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSecondary, setIsOpenSecondary] = useState(false);

  const openNotification = () => {
    const btn = (
      <Space>
        <PrimaryButton
          primaryText={t("cookies.button")}
          secondaryText=""
          onClick={() => agree()}
          large={false}
        />
      </Space>
    );
    api.open({
      message: "Cookies",
      placement: "bottomLeft",
      description: t("cookies.notice"),
      btn,
      key,
      duration: 0,
      onClose: () => {},
    });
    // Ao navegar em nosso site, você concorda com o uso de cookies para melhorar sua experiência e coletar informações do site.
  };

  const [scrollPosition, setScrollPosition] = useState<number>(0);

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  function isCalendlyEvent(e: MessageEvent<any>) {
    return e.origin === "https://calendly.com" && e.data.event && e.data.event.indexOf("calendly.") === 0;
  };

  useEffect(() => {
    const lang = location.pathname.startsWith("/pt") ? "pt" : "en";
    i18n.changeLanguage(lang);
    document.documentElement.lang = lang;
    if (!Cookies.get("melinastylist.com")) {
      openNotification();
    }
    window.addEventListener('scroll', handleScroll, { passive: true });

    window.addEventListener("message", function(e) {
      if(isCalendlyEvent(e)) {
        /* Example to get the name of the event */
        if (e.data.event === "calendly.event_scheduled") {
          TagManager.dataLayer({
            dataLayer: {
              event: 'conversion_event_book_appointment'
            }
          });
        }
      }
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location.pathname, i18n]);

  const services: MenuProps["items"] = [
    {
      key: "5",
      label: (
        <a
          rel="noopener noreferrer"
          href={`${
            location.pathname.startsWith("/pt")
              ? "/pt/style-makeover"
              : "style-makeover"
          }`}
        >
          {t("navigation.default.services.styleMakeover")}
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          rel="noopener noreferrer"
          href={`${
            location.pathname.startsWith("/pt")
              ? "/pt/style-discovery"
              : "style-discovery"
          }`}
        >
          {t("navigation.default.services.styleDiscovery")}
        </a>
      ),
    },
    {
      key: "1",
      label: (
        <a
          rel="noopener noreferrer"
          href={`${
            location.pathname.startsWith("/pt")
              ? "/pt/colour-analysis"
              : "colour-analysis"
          }`}
        >
          {t("navigation.default.services.colourAnalysis")}
        </a>
      ),
    },
    {
      key: "3",
      label: (
        <a
          rel="noopener noreferrer"
          href={`${
            location.pathname.startsWith("/pt")
              ? "/pt/wardrobe-edit"
              : "wardrobe-edit"
          }`}
        >
          {t("navigation.default.services.wardrobeEdit")}
        </a>
      ),
    },
    {
      key: "4",
      label: (
        <a
          rel="noopener noreferrer"
          href={`${
            location.pathname.startsWith("/pt")
              ? "/pt/personal-shopping"
              : "personal-shopping"
          }`}
        >
          {t("navigation.default.services.personalShopping")}
        </a>
      ),
    },
  ];

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <a
          rel="noopener noreferrer"
          href={`${
            location.pathname.startsWith("/pt") ? "/pt/about" : "about"
          }`}
        >
          {t("navigation.default.about")}
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <Dropdown
          menu={{ items: services }}
          placement="bottom"
          arrow={{ pointAtCenter: true }}
          autoFocus={false}
          overlayStyle={{ letterSpacing: "-0.05rem", fontWeight: 300 }}
        >
          <Space>{t("navigation.default.services.title")}</Space>
        </Dropdown>
      ),
    },
    {
      key: "3",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://medium.com/@melinastylist"
        >
          {t("navigation.default.blog")}
        </a>
      ),
    },
    {
      key: "4",
      label: (
        <a
          rel="noopener noreferrer"
          href={`${
            location.pathname.startsWith("/pt") ? "/pt/contact" : "contact"
          }`}
        >
          {t("navigation.default.contact")}
        </a>
      ),
    },
  ];

  const pageContent = () => {
    if (pageType === PageType.NotFound) {
      return <NotFound />;
    }

    return (
      <PageContent pageType={pageType} bookAction={() => setIsOpen(true)} />
    );
  };

  const metaTitle = () => {
    switch (pageType) {
      case PageType.Home:
        return t("head.home.title");
      case PageType.About:
        return t("head.about.title");
      case PageType.Contact:
        return t("head.contact.title");
      case PageType.StyleMakeover:
        return t("head.styleMakeover.title");
      case PageType.StyleDiscovery:
        return t("head.styleDiscovery.title");
      case PageType.ColourAnalysis:
        return t("head.colourAnalysis.title");
      case PageType.WardrobeEdit:
        return t("head.wardrobeEdit.title");
      case PageType.PersonalShopping:
        return t("head.personalShopping.title");
      case PageType.TermsAndConditions:
        return t("head.termsAndConditions.title");
      case PageType.PrivacyPolicy:
        return t("head.privacyPolicy.title");
      case PageType.NotFound:
        return t("head.notFound.title");
    }
  };

  const metaDescription = () => {
    switch (pageType) {
      case PageType.Home:
        return t("head.home.description");
      case PageType.About:
        return t("head.about.description");
      case PageType.Contact:
        return t("head.contact.description");
      case PageType.StyleMakeover:
        return t("head.styleMakeover.description");
      case PageType.StyleDiscovery:
        return t("head.styleDiscovery.description");
      case PageType.ColourAnalysis:
        return t("head.colourAnalysis.description");
      case PageType.WardrobeEdit:
        return t("head.wardrobeEdit.description");
      case PageType.PersonalShopping:
        return t("head.personalShopping.description");
      case PageType.TermsAndConditions:
        return t("head.termsAndConditions.description");
      case PageType.PrivacyPolicy:
        return t("head.privacyPolicy.description");
      case PageType.NotFound:
        return t("head.notFound.description");
    }
  };

  return (
    <Layout style={{ overflowX: "hidden" }}>
      <Helmet>
        <title>{metaTitle()}</title>
        <meta name="description" content={metaDescription()} />
        <link rel="canonical" href={`https://www.melinastylist.com${location.pathname}`} />
        <meta property="og:title" content={metaTitle()} />
        <meta property="og:description" content={metaDescription()} />
        <meta
          property="og:image"
          content="https://www.melinastylist.com/image.jpg"
        />
        <meta property="og:url" content="https://www.melinastylist.com/" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={metaTitle()} />
        <meta name="twitter:description" content={metaDescription()} />
        <meta
          name="twitter:image"
          content="https://www.melinastylist.com/image.jpg"
        />
        <meta name="robots" content="index, follow" />
        <link
          rel="alternate"
          hrefLang="en"
          href={`https://www.melinastylist.com${location.pathname.startsWith("/pt") ? location.pathname.replace("/pt", "") :  location.pathname}`}
        />
        <link
          rel="alternate"
          hrefLang="pt"
          href={`https://www.melinastylist.com${location.pathname.startsWith("/pt") ? location.pathname : "/pt" + location.pathname}`}
        />
        <link rel="icon" type="image/png" href="https://www.melinastylist.com/favicon.ico" />
      </Helmet>
      {messageContextHolder}
      {contextHolder}
      <Header
        className={`${scrollPosition < 100.0 ? "ms-nav" : "ms-nav ms-nav--scrolled"}`}
        style={{
          top: 0,
          zIndex: 1,
          width: "100%",
          display: "flex",
          alignItems: "center",
          backgroundColor: "#fff",
          fontSize: "1.125rem",
        }}
      >
        <Nav
          menuItems={items}
          logoHref={`${location.pathname.startsWith("/pt") ? "/pt/" : "/"}`}
          bookOnClick={() => setIsOpen(true)}
        />
      </Header>
      <Layout>
        <Content>
          {pageContent()}
          {leadModal()}
        </Content>
      </Layout>
      <Footer>
        <FooterContent />
      </Footer>
      <FloatButton
        icon={<WhatsAppOutlined />}
        className="ms-float-button"
        href={`https://wa.me/447904855973?text=${t(`whatsapp.introduction`)}`}
        target="_blank"
      />
      <FloatButton.BackTop
        icon={<ArrowUpOutlined />}
        className="ms-float-button"
        style={{ right: 24, bottom: 100 }}
      />
      <PopupModal
        url="https://calendly.com/melinastylist/free-consultation"
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={root}
        onModalClose={() => setIsOpen(false)}
        open={isOpen}
        />
    </Layout>
  );
};

const App = () => {
  const isBigScreen = useMediaQuery({ query: "(min-width: 992px)" });

  function EnglishWrapper() {
    const { i18n } = useTranslation();

    useEffect(() => {
      i18n.changeLanguage("en");
    }, [i18n]);

    return <Outlet />;
  }

  function PortugueseWrapper() {
    const { i18n } = useTranslation();

    useEffect(() => {
      i18n.changeLanguage("pt");
    }, [i18n]);

    return <Outlet />;
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Open Sans",
          colorBgContainer: "#fff",
          fontSize: 18,
          colorPrimary: "#000",
          colorBgElevated: "#000",
          colorText: "#000",
          colorBorder: "#DDD",
          controlOutline: "#000",
          controlOutlineWidth: 0,
          controlItemBgHover: "rgba(211, 241, 28, 0.1)",
          borderRadiusOuter: 10,
        },
        components: {
          Layout: {
            headerBg: "#ffffff",
            headerColor: "#000000",
            bodyBg: "#ffffff",
            footerBg: "#000",
            headerHeight: isBigScreen ? 128 : 64,
            footerPadding: 64,
            //algorithm: true
          },
          Dropdown: {
            colorBgBase: "#000",
            colorBgContainer: "#000",
            colorBgElevated: "#000",
          },
          Modal: {
            contentBg: "#fff",
          },
        },
      }}
    >
      <Router>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<EnglishWrapper />}>
            <Route index element={<Page pageType={PageType.Home} />} />
            <Route path="about" element={<Page pageType={PageType.About} />} />
            <Route
              path="contact"
              element={<Page pageType={PageType.Contact} />}
            />
            <Route
              path="colour-analysis"
              element={<Page pageType={PageType.ColourAnalysis} />}
            />
            <Route
              path="style-discovery"
              element={<Page pageType={PageType.StyleDiscovery} />}
            />
            <Route
              path="wardrobe-edit"
              element={<Page pageType={PageType.WardrobeEdit} />}
            />
            <Route
              path="personal-shopping"
              element={<Page pageType={PageType.PersonalShopping} />}
            />
            <Route
              path="style-makeover"
              element={<Page pageType={PageType.StyleMakeover} />}
            />
            <Route
              path="terms-and-conditions"
              element={<Page pageType={PageType.TermsAndConditions} />}
            />
            <Route
              path="privacy-policy"
              element={<Page pageType={PageType.PrivacyPolicy} />}
            />
            <Route
              path="blog"
              element={<Page pageType={PageType.Blog} />} 
            />
          </Route>
          <Route path="/pt/*" element={<PortugueseWrapper />}>
            <Route index element={<Page pageType={PageType.Home} />} />
            <Route path="about" element={<Page pageType={PageType.About} />} />
            <Route
              path="contact"
              element={<Page pageType={PageType.Contact} />}
            />
            <Route
              path="blog"
              element={<Page pageType={PageType.Blog} />} 
            />
            <Route
              path="colour-analysis"
              element={<Page pageType={PageType.ColourAnalysis} />}
            />
            <Route
              path="style-discovery"
              element={<Page pageType={PageType.StyleDiscovery} />}
            />
            <Route
              path="wardrobe-edit"
              element={<Page pageType={PageType.WardrobeEdit} />}
            />
            <Route
              path="personal-shopping"
              element={<Page pageType={PageType.PersonalShopping} />}
            />
            <Route
              path="style-makeover"
              element={<Page pageType={PageType.StyleMakeover} />}
            />
            <Route
              path="terms-and-conditions"
              element={<Page pageType={PageType.TermsAndConditions} />}
            />
            <Route
              path="privacy-policy"
              element={<Page pageType={PageType.PrivacyPolicy} />}
            />
          </Route>
        </Routes>
      </Router>
    </ConfigProvider>
  );
};

export default App;
