import { Row, Col, ConfigProvider, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
// import Swiper core and required modules
import { register } from "swiper/element/bundle";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Headline from "../../headline/Headline";
import "./servicescarousel.css";
import { ServicesCarouselProps } from "../../../models/ServicesCarouselProps";

register();

const ServicesCarousel: React.FC<ServicesCarouselProps> = ({
  title,
  slides,
  links,
  autoplay,
  interval,
}) => {
  const swiperElRef = useRef(null);

  const [slidesPerView, setSlidesPerView] = useState(
    calculateSlidesPerView(window.innerWidth)
  );

  const autoplayDelay = interval * 1000;

  useEffect(() => {
    const handleResize = () => {
      setSlidesPerView(calculateSlidesPerView(window.innerWidth));
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function calculateSlidesPerView(width: number): number {
    if (width <= 480) {
      return 1; // 1 slide for mobile
    } else if (width <= 768) {
      return 2; // 2 slides for tablet
    } else if (width <= 992) {
      return 3;
    } else if (width <= 1200) {
      return 4;
    } else {
      return 5;
    }
  }

  // TODO Exlcude slide for current service

  function primaryTitle(title: string) {
    return title.split(" ")[0];
  }

  function secondaryTitle(title: string) {
    const other = title.split(" ");
    other.shift();
    return other;
  }

  return (
    <swiper-container
      className="ms-services-carousel"
      ref={swiperElRef}
      navigation={false}
      pagination={false}
      autoplay={autoplay}
      autoplay-delay={autoplayDelay}
      loop={true}
      slides-per-view={slidesPerView}
      space-between={1}
      breakpointsBase="window"
      style={{ paddingBottom: '2rem' }}
    >
      {slides.map((slide, index) => (
        <swiper-slide key={index}>
          <a
           className="ms-services__slide"
            href={`/${links[index]}`}
            style={{ height: "100%", width: "100%", overflow: "hidden", display: 'block'}}
          >
          <div className="ms-services__slide__container" style={{ transform: 'translateY(3px)' }}>
            <img
              src={slide.imageUrl}
              alt={slide.description}
              style={{
                width: "100%",
                height: "100%",
                zIndex: 100,
              }}
            />
            <div
                className="ms-services__slide__overlay"
                style={{
                  position: "absolute",
                  backgroundColor: "#373737",
                  opacity: 0.6,
                  width: "100%",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0
                }}
              ></div>
            <div
              style={{
                width: "100%",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <ConfigProvider
                theme={{
                  token: {
                    /* here is your global tokens */
                    colorText: "#fff",
                    fontSize: 10,
                  },
                }}
              >
                <Typography.Title className="ms-services__slide__title" color="#fff" style={{ textAlign: "center" }}>
                  <span
                    style={{ letterSpacing: "-0.1rem", paddingRight: "0.1rem" }}
                  >
                    {primaryTitle(slide.title)}
                  </span>
                  <span
                    style={{
                      fontWeight: 300,
                      letterSpacing: "-0.1rem",
                      whiteSpace: "pre",
                    }}
                  >
                    {secondaryTitle(slide.title)}
                  </span>
                </Typography.Title>
              </ConfigProvider>
              </div>
            </div>
          </a>
        </swiper-slide>
      ))}
    </swiper-container>
  );
};

export default ServicesCarousel;
