import { Layout, Row, Col } from "antd";
import React from "react";
import { useMediaQuery } from "react-responsive";
import "./footer.css";
import SocialIcon, { SocialIconType } from "../socialicon/SocialIcon";
import { Trans, useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const FooterContent: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const today = new Date();
  const year = today.getFullYear();
  const isBigScreen = useMediaQuery({ query: "(min-width: 992px)" });

  const googlePrivacyPolicy = (
    <a
      style={{ color: "#fff", fontWeight: 700 }}
      href="https://policies.google.com/privacy"
    >
      {t("footer.privacyPolicy")}
    </a>
  );
  const googleTerms = (
    <a
      style={{ color: "#fff", fontWeight: 700 }}
      href="https://policies.google.com/terms"
    >
      {t("footer.termsAndConditions")}
    </a>
  );

  return (
    <Layout style={{ backgroundColor: "black" }}>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col xs={{ span: 24, order: 4 }} lg={{ span: 12, order: 2 }}>
          <p
            style={{
              color: "#fff",
              textAlign: "center",
              fontWeight: "300",
              paddingBottom: "0.5rem",
            }}
          >
            {year} &copy; melinastylist.com
          </p>
          <p style={{ color: "#fff", fontSize: "10px", textAlign: "center", paddingTop: '1rem', lineHeight: '1rem' }}>
            {t('footer.recaptcha')}
          </p>
        </Col>
        <Col xs={{ span: 24, order: 2 }} lg={{ span: 6, order: 1 }}>
          <Row align="middle" gutter={[0, 6]}>
            <Col xs={{ span: 24, order: 2 }}>
              <a
                href={`${
                  location.pathname.startsWith("/pt")
                    ? "/pt/terms-and-conditions"
                    : "/terms-and-conditions"
                }`}
                style={{
                  color: "#fff",
                  fontSize: "0.75rem",
                  textAlign: "center",
                  display: "inline-block",
                  width: "100%",
                }}
              >
                {t("footer.termsAndConditions")}
              </a>
            </Col>
            <Col xs={{ span: 24, order: 3 }}>
              <a
                href={`${
                  location.pathname.startsWith("/pt")
                    ? "/pt/privacy-policy"
                    : "/privacy-policy"
                }`}
                style={{
                  color: "#fff",
                  fontSize: "0.75rem",
                  textAlign: "center",
                  display: "inline-block",
                  width: "100%",
                }}
              >
                {t("footer.privacyPolicy")}
              </a>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24, order: 1 }} lg={{ span: 6, order: 3 }}>
          <Row gutter={16} justify="center">
            <Col>
              <SocialIcon
                type={SocialIconType.Instagram}
                color="#fff"
                size={28}
                href="https://www.instagram.com/melinastylist_/"
              />
            </Col>
            <Col>
              <SocialIcon
                type={SocialIconType.LinkedIn}
                color="#fff"
                size={28}
                href="https://www.linkedin.com/in/melinapsilva/"
              />
            </Col>
            <Col>
              <SocialIcon
                type={SocialIconType.Pinterest}
                color="#fff"
                size={26}
                href="https://www.pinterest.co.uk/melinasilvastylist/"
              />
            </Col>
            <Col>
              <SocialIcon
                type={SocialIconType.Medium}
                color="#fff"
                size={26}
                href="https://www.medium.com/@melinastylist"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  );
};

export default FooterContent;
