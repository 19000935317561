import { Component } from 'react';
import SVG from 'react-inlinesvg';
import { MenuState } from '../hamburger/Hamburger';
import logo from '../../ms-logo.svg';
import './logo.css';


class Logo extends Component<MenuState> {
    render() {
        return (
            <a href={this.props.href} style={{display: 'block', height: '2.5rem'}}>
                <SVG 
                style={{width: 'auto', height: '2.5rem'}}
                className={`ms-logo ${this.props.isOpen && 'ms-logo--white'}`}
                src={logo}
                title="Melina Stylist Logo"
                />
            </a>
        );
    }
}

export default Logo;