import React from 'react';
import { FullWidthImageProps } from '../../models/FullWidthImageProps';
import { useMediaQuery } from 'react-responsive';
import './fullwidthimage.css';


const FullWidthImage: React.FC<FullWidthImageProps> = ({
    mobileImageUrl,
    desktopImageUrl
}) => {
    const isBigScreen = useMediaQuery({ query: "(min-width: 992px)" });

    return (
        <img className='ms-full-width-image ms-bottom--spacer' src={`${isBigScreen ? desktopImageUrl : mobileImageUrl}`}  alt="Contact Image"/>
    );
};

export default FullWidthImage;