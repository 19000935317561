import { Row, Col } from "antd";
import React, { useRef } from "react";
// import Swiper core and required modules
import { register } from "swiper/element/bundle";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Quote from "./quote/Quote";
import "./testimonialcarousel.css";
import TestimonialControls from "./nav/TestimonialControls";
import { TestimonialCarouselProps } from "../../../models/TestimonialCarouselProps";

register();

const TestimonialCarousel: React.FC<TestimonialCarouselProps> = ({
  title,
  reviews,
  autoplay,
  interval
}) => {
  const swiperElRef = useRef(null);

  const splitReviews = reviews.split("<br>");

  const intervalInMs = () => {
    return interval*1000;
  }

  return (
    <Row className="ms-testimonial-carousel ms-carousel ms-bottom--spacer">
      <Col span={24}>
        <TestimonialControls />
        <h4 className="ms-testimonial-carousel__title">{title}</h4>
        <swiper-container       
            ref={swiperElRef}
            slides-per-view="1"
            navigation={true}
            navigation-prev-el='.ms-testimonial-carousel__left'
            navigation-next-el='.ms-testimonial-carousel__right'
            pagination={false}
            autoplay-enabled={autoplay}
            autoplay-delay={intervalInMs()}
            autoplay-disable-on-interaction={false}
            loop={true}
            centerInsufficientSlides={true}
            slideNextClass='.ms-testimonial-carousel__right'>
          {splitReviews.map((slide, index) => (
            <swiper-slide key={index}>
              <Quote quote={slide.split(" | ")[0]} name={slide.split(" | ")[1]} />
            </swiper-slide>
          ))}
        </swiper-container>
      </Col>
    </Row>
  );
};

export default TestimonialCarousel;
