import React, { useState } from "react";
import { Layout, Row, Col, Form, message } from "antd";
import { useMediaQuery } from "react-responsive";
import { NewsletterFormProps } from "../../models/NewsletterFormProps";
import Headline from "../headline/Headline";
import Text from "../text/Text";
import "./newsletter.css";
import FormField, { FormFieldType } from "../form/fields/FormField";
import ArrowButton from "../arrow-button/ArrowButton";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Lottie from "lottie-react";
import sentAnimation from "../../assets/animations/sentAnimation.json";
import { useLocation } from "react-router-dom";
import { t } from "i18next";
import TagManager from "react-gtm-module";

const NewsletterForm: React.FC<NewsletterFormProps> = ({
  title,
  text,
  successMessage,
  privacyNotice,
  emailPlaceholder,
}) => {
  const [primaryTitle, ...restOfWords] = title.split(" ");
  const secondaryTitle = restOfWords.join(" ");
  const isBigScreen = useMediaQuery({ query: "(min-width: 992px)" });
  const [form] = Form.useForm();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [sent, setSent] = useState(false);
  const [success, setSuccess] = useState(false);
  const location = useLocation();

  const onSubmit = async (values: any) => {
    if (!executeRecaptcha) {
      return;
    }
    sendMessage();
    const token = await executeRecaptcha("NewsletterForm");

    const json = {
      token: token,
      language: location.pathname.startsWith("/pt") ? "pt" : "en",
      email: values.email,
    };
    setSent(true);
    try {
      const baseUrl = window.location.origin.replace(":3000", ":8080");
      const response = await fetch(baseUrl + "/api/newsletter", {
        method: "POST",
        mode: "cors", // Change the mode to CORS
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(json),
      });
      if (!response.ok) {
        openMessage();
        setSent(false);
        throw new Error(`HTTP error! Status: ${response.status}`);
      } else {
        setSuccess(true);
        // GTAG Conversion Tracking
        TagManager.dataLayer({
          dataLayer: {
            event: 'conversion_event_signup'
          }
        });
      }
    } catch (error) {
      console.error("There was an error sending the form:", error);
    }
  };

  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";

  const sendMessage = () => {
    messageApi.open({
      key,
      type: "loading",
      content: t("messages.sending"),
      style: { color: "white" },
    });
  };

  const openMessage = () => {
    setTimeout(() => {
      messageApi.open({
        key,
        type: "warning",
        content: t('errors.newsletter'),
        duration: 5,
        style: { color: "white" },
      });
    }, 500);
  };

  const successMessageComponent = () => {
    return (
      <div className="ms-newsletter__success">
        <Col style={{ paddingBottom: "2rem" }}>
          <Lottie
            animationData={sentAnimation}
            loop={false}
            style={{ width: "100%", height: "4rem", textAlign: "center" }}
          />
        </Col>
        <Col>
          <Text text={successMessage} align="center" />
        </Col>
      </div>
    );
  };

  return (
    <Layout
      className={`${
        success ? "ms-newsletter__sent" : ""
      } ms-newsletter ms-content ms-bottom--spacer`}
    >
      {contextHolder}
      <Row className="" justify="center" gutter={[20, 20]}>
        {success && successMessageComponent()}
        <Col className="ms-newsletter__title" span={24}>
          <Headline
            title={primaryTitle}
            subtitle={secondaryTitle}
            align={`${isBigScreen ? "center" : "left"}`}
          />
        </Col>
        <Col
          className="ms-newsletter__text"
          xs={{ span: 24 }}
          lg={{ span: 10 }}
        >
          <Text align={`${isBigScreen ? "center" : "left"}`} text={text} />
        </Col>
        <Col className="ms-newsletter__form" span={24}>
          <Form form={form} onFinish={onSubmit}>
            <Row justify="center" wrap={false}>
              <Col xs={{ span: 22 }} lg={{ span: 6 }}>
                <FormField
                  type={FormFieldType.Email}
                  name="email"
                  label={emailPlaceholder}
                  required
                />
              </Col>
              <Col>
                <ArrowButton disabled={sent} />
              </Col>
            </Row>
          </Form>
        </Col>
        <Col>
          <p className="ms-newsletter__privacy">{privacyNotice}</p>
        </Col>
      </Row>
    </Layout>
  );
};

export default NewsletterForm;
