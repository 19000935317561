import React, { Component } from 'react'
import SVG from 'react-inlinesvg';

export interface IconProps {
    className: string;
    title: string,
    color: string;
    height: number;
    src: string;
}

class Icon extends Component<IconProps> {
    render() {
        return (
            <span>
                <SVG 
                    src={this.props.src}
                    title={this.props.title}
                    fill={this.props.color}
                    style={{ width: 'auto', height: `${this.props.height}px`}}
                    className={this.props.className}
                    />
            </span>
        );
    }
}

export default Icon;