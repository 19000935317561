import React, { useState } from "react";
import type { CollapseProps } from "antd";
import { Collapse, Select } from "antd";
import { CaretDownOutlined } from '@ant-design/icons'
import FAQHeader from "./header/FAQHeader";
import { FAQProps } from "../../models/FAQProps";
import './faq.css';
type ExpandIconPosition = "start" | "end";

const FAQs: React.FC<FAQProps> = ({
  title,
  serviceNamePart1,
  serviceNamePart2,
  questions
 }) => {
  const [expandIconPosition, setExpandIconPosition] =
    useState<ExpandIconPosition>("end");

  const onPositionChange = (newExpandIconPosition: ExpandIconPosition) => {
    setExpandIconPosition(newExpandIconPosition);
  };

  const questionsArr = questions.split('<br>');

  const splitQuestions = questionsArr.map(item => {
    const [question, answer] = item.split(' | ');
    return {
      question,
      answer
    };
  });

  function bulletPointsToList(input: string) {
    // Split the string by "•" and filter out empty or whitespace-only strings
    const items = input.split('•').filter(item => item.trim() !== '');
  
    // Map each item to an <li> element
    return (
      <ul style={{ listStylePosition: 'outside', paddingLeft: '1rem' }}>
        {items.map((item, index) => (
          <li key={index}>{item.trim()}</li>
        ))}
      </ul>
    );
  }

  function faqContent(input: string) {
    const items = input.split('*').filter(item => item.trim() !== '');
    return (
      <>
        {items.map((item, index) => (
          <p key={index}>{item}</p>
        ))}
      </>
    );
  }

  function list(): CollapseProps["items"] {
    const newItems: CollapseProps["items"] = [];
    for (let i = 0; i < splitQuestions.length; i++) {
      newItems.push({
        key: i,
        label: splitQuestions[i].question,
        children: <div className="ms-faq__answer">{splitQuestions[i].answer.startsWith("•") ? bulletPointsToList(splitQuestions[i].answer) : (splitQuestions[i].answer.includes("*") ? faqContent(splitQuestions[i].answer) : splitQuestions[i].answer)}</div>
      });
    }
    return newItems;
  }

  return (
    <section className="ms-faq ms-bottom--spacer">
      <FAQHeader primary={serviceNamePart1} secondary={serviceNamePart2} />
      <Collapse 
        className="ms-component"
        items={list()}
        expandIcon={({ isActive }) => <CaretDownOutlined style={{ fontSize: '20px' }} rotate={isActive ? 180 : 0} />}
        expandIconPosition="end"
        size="large"
        accordion={false}
        ghost />
    </section>
  );
};

export default FAQs;
