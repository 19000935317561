import React, { useRef, useEffect, useState } from "react";
import MediaQuery from "react-responsive";
import { InlineVideoProps } from "../../models/InlineVideoProps";
import { Col, Layout, Row } from "antd";
import Headline from "../headline/Headline";
import Button from "../button/Button";
import './video.css';

const InlineVideo: React.FC<InlineVideoProps> = ({
  mobileVideoApiVideo,
  desktopVideoApiVideo,
  button,
  slogan,
  bookAction
}) => {
  const [textVisible, showText] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [primaryButtonText, ...restOfWords] = button.split(' ');
  const secondaryButtonText = restOfWords.join(' ');

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }

    setTimeout(function () {
      showText(true);
    }, 1000);
    
  }, []);

  return (
    <>
      <MediaQuery maxWidth={767}>
        <div className="ms-video-wrapper ms-bottom--spacer">
          <div className={`${textVisible ? 'ms-video-background ms-video-wrapper__overlay--visible' : 'ms-video-background'}`}></div>
          <Layout className={`${textVisible ? 'ms-video-wrapper__overlay ms-video-wrapper__overlay--visible' : 'ms-video-wrapper__overlay'}`}>
            <Row justify='center'>
              <Col span={24}>
                <Headline title={slogan} subtitle="" level="h1" color="white" align="center" />
              </Col>
              <Row justify='center'>
                <Col span={24}>
                  <Button
                      large={true}
                      primaryText={primaryButtonText}
                      secondaryText={secondaryButtonText}
                      onClick={() => bookAction()}
                      />
                </Col>
              </Row>
            </Row>
          </Layout>
          <video
            ref={videoRef}
            width="100%"
            height="100%"
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              objectFit: "cover",
              zIndex: 0, // assuming you want it in the background
              height: 'calc(100vh)',
              width: '100vw'
            }}
            playsInline
            autoPlay
            muted
            loop
          >
            <source
              src={`https://vod.api.video/vod/${mobileVideoApiVideo.videoId}/mp4/source.mp4`}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
      </MediaQuery>
      <MediaQuery minWidth={768}>
      <div className="ms-video-wrapper ms-bottom--spacer">
          <div className={`${textVisible ? 'ms-video-background ms-video-wrapper__overlay--visible' : 'ms-video-background'}`}></div>
          <Layout className={`${textVisible ? 'ms-video-wrapper__overlay ms-video-wrapper__overlay--visible' : 'ms-video-wrapper__overlay'}`}>
            <Row justify='center'>
              <Col span={24}>
                <Headline title={slogan} subtitle="" level="h1" color="white" align="center" />
              </Col>
              <Col span={24}>
                {/* <Text align="center" text="this is the text" color="white" /> */}
              </Col>
              <Row justify='center'>
                <Col span={24}>
                  <Button
                      large={true}
                      primaryText={primaryButtonText}
                      secondaryText={secondaryButtonText}
                      onClick={() => bookAction()}
                      />
                </Col>
              </Row>
            </Row>
          </Layout>
          <video
            className="ms-bottom--spacer"
            ref={videoRef}
            width="100%"
            height="100%"
            style={{
              position: "relative",
              left: "0",
              right: "0",
              objectFit: "cover",
              zIndex: 0, // assuming you want it in the background
              height: 'calc(100vh)',
              width: '100vw'
            }}
            playsInline
            auto-buffer="true"
            autoPlay
            muted
            loop
          >
            <source
              src={`https://vod.api.video/vod/${desktopVideoApiVideo.videoId}/mp4/source.mp4`}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
      </MediaQuery>
    </>
  );
};

export default InlineVideo;
