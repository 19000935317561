import React, { useState } from "react";
import {
  Form,
  Layout,
  Row,
  Col,
  Space,
  ConfigProvider,
  theme,
  CollapseProps,
  Collapse,
} from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import FormField, { FormFieldType } from "./fields/FormField";
import MediaQuery, { useMediaQuery } from "react-responsive";
import PrimaryButton from "../button/Button";
import Headline from "../headline/Headline";
import Text from "../text/Text";
import "./form.css";
import { ContactFormProps } from "../../models/ContactFormProps";
import { useTranslation } from "react-i18next";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Lottie from "lottie-react";
import sentAnimation from "../../assets/animations/sentAnimation.json";
import TagManager from "react-gtm-module";

interface FieldData {
  name: string | number | boolean | (string | number | boolean)[];
  value?: any;
}

const ContactForm: React.FC<ContactFormProps> = ({
  title,
  text,
  successMessage,
  privacyNotice,
}) => {
  const [fields, setFields] = useState<FieldData[]>([
    { name: ["name"], value: "" },
    { name: ["phone"], value: "" },
    { name: ["email"], value: "" },
    { name: ["message"], value: "" },
    { name: ["style-makeover"], value: false },
    { name: ["style-discovery"], value: false },
    { name: ["colour-analysis"], value: false },
    { name: ["personal-shopping"], value: false },
    { name: ["wardrobe-edit"], value: false },
    { name: ["other"], value: false },
  ]);

  const isBigScreen = useMediaQuery({ query: "(min-width: 992px)" });
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const [submittable, setSubmittable] = React.useState(false);
  const [primaryTitle, ...restOfWords] = title.split(" ");
  const secondaryTitle = restOfWords.join(" ");
  const { t, i18n } = useTranslation();
  const { defaultAlgorithm, darkAlgorithm } = theme;
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [success, setSuccess] = useState(false);
  const [sending, setSending] = useState(false);
  const onFinish = async (values: any) => {
    setSending(true);

    if (!executeRecaptcha) {
      return;
    }
    const token = await executeRecaptcha("ContactForm");

    const json = {
      token: token,
      name: values.name,
      email: values.email,
      phone: values.phone,
      message: values.message,
      services: filterTrueBooleans(values),
    };

    try {
      const baseUrl = window.location.origin.replace(":3000", ":8080");
      const response = await fetch(baseUrl + "/api/contact", {
        method: "POST",
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(json),
      });

      if (!response.ok) {
        setSending(false);
        throw new Error(`HTTP error! Status: ${response.status}`);
      } else {
        setSending(false);
        setSuccess(true);
        TagManager.dataLayer({
          dataLayer: {
            event: 'conversion_event_contact'
          }
        });
      }
    } catch (error) {
      setSending(false);
      console.error("There was an error sending the form:", error);
    }
  };

  const successMessageComponent = () => {
    return (
      <div className="ms-form__success">
        <Col style={{ paddingBottom: "2rem" }}>
          <Lottie
            animationData={sentAnimation}
            loop={false}
            style={{ width: "100%", height: "4rem", textAlign: "center" }}
          />
        </Col>
        <Col>
          <Text text={successMessage} align="center" />
        </Col>
      </div>
    );
  };

  function filterTrueBooleans(input: any) {
    const services = [];

    for (let key in input) {
      if (typeof input[key] === "boolean" && input[key] === true) {
        services.push(key);
      }
    }

    return services;
  }

  React.useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [values]);

  const checkboxItems = () => {
    return [
      <FormField
        key={0}
        type={FormFieldType.Checkbox}
        name="style-makeover"
        label={t("contact.styleMakeover")}
        required={false}
      />,
      <FormField
        key={1}
        type={FormFieldType.Checkbox}
        name="style-discovery"
        label={t("contact.styleDiscovery")}
        required={false}
      />,
      <FormField
        key={2}
        type={FormFieldType.Checkbox}
        name="colour-analysis"
        label={t("contact.colourAnalysis")}
        required={false}
      />,
      <FormField
        key={3}
        type={FormFieldType.Checkbox}
        name="wardrobe-edit"
        label={t("contact.wardrobeEdit")}
        required={false}
      />,
      <FormField
        key={4}
        type={FormFieldType.Checkbox}
        name="personal-shopping"
        label={t("contact.personalShopping")}
        required={false}
      />,
      <FormField
        key={5}
        type={FormFieldType.Checkbox}
        name="other"
        label={t("contact.other")}
        required={false}
      />,
    ];
  };

  function collapsibleChecboxComponent(): CollapseProps["items"] {
    const newItems: CollapseProps["items"] = [];
    newItems.push({
      key: "checkboxes",
      label: t("contact.queryTitle"),
      children: checkboxItems(),
    });
    return newItems;
  }

  const checkboxComponent = () => {
    if (isBigScreen) {
      return (
        <>
          <label
            style={{
              lineHeight: "2.875rem",
              height: "4.375rem",
              paddingBottom: "3rem",
              display: "block",
            }}
          >
            {t("contact.queryTitle")}
          </label>
          {checkboxItems()}
        </>
      );
    }
    return (
      <>
        <Collapse
          className="ms-content"
          items={collapsibleChecboxComponent()}
          expandIcon={({ isActive }) => (
            <CaretDownOutlined
              style={{ fontSize: "20px" }}
              rotate={isActive ? 180 : 0}
            />
          )}
          expandIconPosition="end"
          size="large"
          accordion={false}
          ghost
        />
      </>
    );
  };

  return (
    <ConfigProvider>
      <Layout className={`${success ? 'ms-form__sent' : ''} ms-component ms-bottom--spacer`}>
      {success && successMessageComponent()}
        <Row justify="center">
          <Col className="ms-form__headline">
            <Headline
              title={`${primaryTitle} `}
              subtitle={`${secondaryTitle} `}
              level="h1"
            />
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={{ span: 20 }} lg={{ span: 10 }} className="ms-form__text">
            <Text align="center" text={text} />
          </Col>
        </Row>
        <Row justify="center" className="ms-form" gutter={[25, 25]}>
          <Col span={24}>
            <Form
              id="ms-contact-form"
              form={form}
              layout="vertical"
              fields={fields}
              onFinish={onFinish}
            >
              <Row gutter={30}>
                <Col xs={{ span: 24 }} lg={{ span: 16 }}>
                  <FormField
                    type={FormFieldType.Text}
                    name="name"
                    label={t("contact.name")}
                    required
                  />
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                  <FormField
                    type={FormFieldType.Phone}
                    name="phone"
                    label={t("contact.phone")}
                    required={false}
                  />
                </Col>
              </Row>
              <Row gutter={30} justify="start">
                <Col xs={{ span: 24 }} lg={{ span: 16 }}>
                  <FormField
                    type={FormFieldType.Email}
                    name="email"
                    label={t("contact.email")}
                    required
                  />
                  <MediaQuery minWidth={992}>
                    <FormField
                      type={FormFieldType.TextArea}
                      name="message"
                      label={t("contact.message")}
                      showLabel={true}
                      required
                    />
                  </MediaQuery>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                  {checkboxComponent()}
                  <MediaQuery maxWidth={991}>
                    <FormField
                      type={FormFieldType.TextArea}
                      name="message"
                      label={t("contact.message")}
                      showLabel={true}
                      required={false}
                    />
                  </MediaQuery>
                </Col>
              </Row>
              <Row justify="center">
                <Col>
                  <PrimaryButton
                    primaryText={t("contact.send")}
                    secondaryText=""
                    large={isBigScreen}
                    isSubmit={true}
                    disabled={!submittable}
                    loading={sending}
                    onClick={() => {}}
                  />
                </Col>
              </Row>
            </Form>
          </Col>
          {privacyNotice && (
            <Col>
              <p className="ms-form__privacy">{privacyNotice}</p>
            </Col>
          )}
        </Row>
      </Layout>
    </ConfigProvider>
  );
};

export default ContactForm;
