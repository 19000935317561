import { Row, Col } from 'antd';
import React from 'react';
import './price.css';

interface PriceProps {
    price: string;
    info: string;
}

const Price: React.FC<PriceProps> = ({ price, info }) => {
    return (
        <Row className='ms-inner-content'>
            <Col>
                <span className='ms-price-info'><b>{price} |</b></span>
            </Col>
            <Col>
                <span className='ms-price-info'>{info}</span>
            </Col>
        </Row>
    );
};

export default Price;