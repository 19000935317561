import React from 'react';
import { Row, Col } from 'antd';
import NavigationButton from './TestimonialNavButton';

const TestimonialControls: React.FC = () => {
    return(
        <Row justify='space-between' className='ms-testimonials-controls'>
            <Col>
                <NavigationButton direction='left' />
            </Col>
            <Col>
                <NavigationButton direction='right' />
            </Col>
        </Row>
    );
};

export default TestimonialControls;