import React from 'react';
import { ConfigProvider, Typography } from 'antd'; // Assuming you are using antd
import './headline.css';

interface HeadlineProps {
    title: string;
    subtitle: string;
    breaking?: boolean;
    color?: "white" | "black";
    level?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    align?: 'left' | 'center' | 'right'; 
}

const Headline: React.FC<HeadlineProps> = ({ title, subtitle, breaking = false, color, level = 'h2', align }) => {
    const renderHeading = () => {
        switch (level) {
            case 'h1':
                return (
                    <h1 className='ms-headline ms-inner-content' style={{ textAlign: align }}>
                        <Typography.Text color={color} style={{color: color}}>
                            <span className='ms-headline--bold'>{title} </span>
                            {breaking && <br />}
                            <span>{subtitle}</span>
                        </Typography.Text>
                    </h1>
                );
            case 'h3':
                return (
                    <h3 className='ms-headline' style={{ textAlign: align }}>
                        <Typography.Text color={color}  style={{color: color}}>
                            <span className='ms-headline--bold'>{title} </span>
                            {breaking && <br />}
                            <span>{subtitle}</span>
                        </Typography.Text>
                    </h3>
                );
            case 'h4':
                return (
                    <h4 className='ms-headline' style={{ textAlign: align }}>
                        <Typography.Text color={color}  style={{color: color}}>
                            <span className='ms-headline--bold'>{title} </span>
                            {breaking && <br />}
                            <span>{subtitle}</span>
                        </Typography.Text>
                    </h4>
                );
            default:
                return (
                    <ConfigProvider theme={{
                        token: {
                          /* here is your global tokens */
                          colorText: color
                        },
                      }}>
                        <h2 className='ms-headline' style={{ textAlign: align }}>
                            <Typography.Text color={color}  style={{color: color}}>
                                <span className='ms-headline--bold'>{title} </span>
                                {breaking && <br />}
                                <span>{subtitle}</span>
                            </Typography.Text>
                        </h2>
                    </ConfigProvider>
                );
        }
    };

    return renderHeading();
};

export default Headline;