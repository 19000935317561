import React, { Component } from "react";
import { ConfigProvider, Button, Typography } from "antd";
// Styles
import "./button.css";

interface Props {
  large: boolean;
  href?: string;
  primaryText: string;
  secondaryText: string;
  loading?: boolean;
  disabled?: boolean;
  isSubmit?: boolean;
  noHover?: boolean;
  onClick: () => void;
}

class PrimaryButton extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  button() {
    if (this.props.href) {
      return (
        <Button
          htmlType={`${this.props.isSubmit ? "submit" : "button"}`}
          className={
            this.props.large ? "ms-button ms-button--large" : "ms-button"
          }
          size={this.props.large ? `large` : `middle`}
          type="primary"
          shape="round"
          loading={this.props.loading}
          disabled={this.props.disabled}
          onClick={() => this.props.onClick}
          href={this.props.href}
        >
          <span>
            <b className="ms-button-text--bold">{this.props.primaryText} </b>{" "}
            {this.props.secondaryText}
          </span>
        </Button>
      );
    }
    return <Button
      htmlType={`${this.props.isSubmit ? "submit" : "button"}`}
      className={this.props.large ? "ms-button ms-button--large" : "ms-button"}
      size={this.props.large ? `large` : `middle`}
      type="primary"
      shape="round"
      loading={this.props.loading}
      disabled={this.props.disabled}
      onClick={this.props.onClick}
    >
      <span>
        <b className="ms-button-text--bold">{this.props.primaryText} </b>{" "}
        {this.props.secondaryText}
      </span>
    </Button>;
  }

  render() {
    return (
      <ConfigProvider
        theme={{
          components: {
            Button: {
              /* here is your component tokens */
              primaryColor: "#FFFFFF",
              colorPrimary: "#000000",
              colorPrimaryHover: "#D3F11C",
              colorPrimaryActive: "#D3F11C",
              textHoverBg: "#000000",
              linkHoverBg: "#D3F11C",
            },
          },
        }}
      >
        {this.button()}
      </ConfigProvider>
    );
  }
}

export default PrimaryButton;
