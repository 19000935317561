import React from "react";
import SVG from "react-inlinesvg";
import RightArrow from "../../../../assets/arrow-right.svg";
import LeftArrow from "../../../../assets/arrow-left.svg";
import './testimonialnavbutton.css';

interface TestimonialNavButtonProps {
  direction: "left" | "right";
}

const TestimonialNavButton: React.FC<TestimonialNavButtonProps> = ({
  direction
}) => {

  const renderButton = () => {
    switch (direction) {
      case "left":
        return (
          <div className="ms-testimonial-carousel__left" style={{ transform: 'translateY(-50%)', top: '50%' }}>
            <SVG
              style={{ width: "2rem", height: "2rem" }}
              className="ms-logo"
              src={LeftArrow}
              title="Left Arrow Button"
            />
          </div>
        );
      case "right":
        return (
          <div className="ms-testimonial-carousel__right" style={{ transform: 'translateY(-50%)', top: '50%' }}>
            <SVG
              style={{ width: "2rem", height: "2rem" }}
              className="ms-logo"
              src={RightArrow}
              title="Right Arrow Button"
            />
          </div>
        );
    }
  };

  return renderButton();
};

export default TestimonialNavButton;
