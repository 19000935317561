import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import reportWebVitals from "./reportWebVitals";
import { HelmetProvider } from 'react-helmet-async';
import TagManager from "react-gtm-module";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

TagManager.initialize({
  gtmId: 'AW-11197916537',
});

root.render(
  <GoogleReCaptchaProvider
    reCaptchaKey="6LcN6NsmAAAAADMJj6wziWCjvc11FEYSMOK7lQGQ"
    // language="[optional_language]"
    //useRecaptchaNet="[optional_boolean_value]"
    //useEnterprise="[optional_boolean_value]"
    scriptProps={{
      async: false, // optional, default to false,
      defer: false, // optional, default to false
      appendTo: "head", // optional, default to "head", can be "head" or "body",
      nonce: undefined, // optional, default undefined
    }}
    container={{
      // optional to render inside custom element
      element: "recaptcha-container",
      parameters: {
        badge: "inline", // optional, default undefined
        //theme: 'dark', // optional, default undefined
      },
    }}
  >
      <div id="recaptcha-container" style={{ height: 0, width: 0 }}></div>
      <HelmetProvider>
        <App />
      </HelmetProvider>
  </GoogleReCaptchaProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
