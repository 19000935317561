import React from 'react';
import { Layout, Row, Col } from 'antd';
import Headline from '../headline/Headline';
import Text from '../text/Text';
import './infoteaser.css';
import { ServicesTeaserProps } from '../../models/ServicesTeaserProps';

const InfoTeaser: React.FC<ServicesTeaserProps> = ({
    title,
    text
}) => {
    // Properties
    const [primaryTitle, ...restOfWords] = title.split(' ');
    const secondaryTitle = restOfWords.join(' ');

    return (
        <Layout className='ms-infoteaser ms-content ms-bottom--spacer'>
            <Row gutter={{ lg: 30, xl: 60 }}>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    <Headline title={primaryTitle} subtitle={secondaryTitle} breaking level='h2' />
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    {text &&  <Text align='left' text={text} />}
                    {/* <Link text='Find out more' href='#' /> */}
                </Col>
            </Row>
        </Layout>
    );
};

export default InfoTeaser;