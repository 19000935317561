import { Component } from "react";
import { Row, Col, Layout, Space } from "antd";
import { Translation } from "react-i18next";
import MenuItem from "../navigation/menu-item/MenuItem";
import { MenuState } from "../hamburger/Hamburger";
// Styles
import "./mobilemenu.css";
import SocialIcon, { SocialIconType } from "../socialicon/SocialIcon";
import { LanguageSwitcher } from "../navigation/Navigation";
import { useLocation } from "react-router-dom";
import { t } from "i18next";

const MobileMenu: React.FC<MenuState> = ({ isOpen, mediumBlogActive }) => {
  const location = useLocation();
  const lang = location.pathname.startsWith("/pt") ? "pt" : "en";

  const blog = () => {
    if (mediumBlogActive) {
      return(
          <MenuItem
          key="3"
          dropdown={false}
          subItems={[]}
          subItemsSlugs={[]}
          name={
            t("navigation.default.blog") + " "
          }
          url="https://www.medium.com/@melinastylist"
          slug="/"
        />
      );
    }
    return <></>;
  };

  return (
    <div className={`ms-mobile-menu ${isOpen && "ms-mobile-menu--active"}`}>
      <Layout>
        <Row>
          <Col span={24}>
            <Translation>
              {(t, { i18n }) => (
                <ul>
                  <MenuItem
                    key="1"
                    dropdown={false}
                    subItems={[]}
                    subItemsSlugs={[]}
                    name={
                      t("navigation.separated.about.first") +
                      t("navigation.separated.about.second")
                    }
                    slug={`${lang == "en" ? "about" : "pt/about"}`}
                  />
                  <MenuItem
                    key="2"
                    dropdown={true}
                    subItems={[
                      t("navigation.default.services.styleMakeover"),
                      t("navigation.default.services.styleDiscovery"),
                      t("navigation.default.services.colourAnalysis"),
                      t("navigation.default.services.wardrobeEdit"),
                      t("navigation.default.services.personalShopping"),
                    ]}
                    name={
                      t("navigation.separated.services.first") +
                      t("navigation.separated.services.second")
                    }
                    subItemsSlugs={[
                      `${
                        lang == "en" ? "style-makeover" : "pt/style-makeover"
                      }`,
                      `${
                        lang == "en" ? "style-discovery" : "pt/style-discovery"
                      }`,
                      `${
                        lang == "en" ? "colour-analysis" : "pt/colour-analysis"
                      }`,
                      `${lang == "en" ? "wardrobe-edit" : "pt/wardrobe-edit"}`,
                      `${
                        lang == "en"
                          ? "personal-shopping"
                          : "pt/personal-shopping"
                      }`,
                    ]}
                    slug=""
                  />
                  {blog()}
                  <MenuItem
                    key="4"
                    dropdown={false}
                    subItems={[]}
                    subItemsSlugs={[]}
                    name={
                      t("navigation.separated.contact.first") +
                      t("navigation.separated.contact.second")
                    }
                    slug={`${lang == "en" ? "contact" : "pt/contact"}`}
                  />
                </ul>
              )}
            </Translation>
          </Col>
        </Row>
        <Row gutter={[12, 24]} justify="end" align="middle">
          <Col>
            <SocialIcon
              type={SocialIconType.Instagram}
              color="#fff"
              size={24}
              href="https://www.instagram.com/melinastylist_/"
            />
          </Col>
          <Col>
            <SocialIcon
              type={SocialIconType.LinkedIn}
              color="#fff"
              size={24}
              href="https://www.linkedin.com/in/melinapsilva/"
            />
          </Col>
          <Col>
            <SocialIcon
              type={SocialIconType.Pinterest}
              color="#fff"
              size={22}
              paddingTop="2px"
              href="https://www.pinterest.co.uk/melinasilvastylist/"
            />
          </Col>
          <Col>
            <SocialIcon
              type={SocialIconType.Medium}
              color="#fff"
              size={24}
              href="https://www.medium.com/@melinastylist"
            />
          </Col>
        </Row>
      </Layout>
    </div>
  );
};

export default MobileMenu;
