import React from "react";
import "./quote.css";
import { Layout, Row, Col } from "antd";
import { useMediaQuery } from "react-responsive";

interface QuoteProps {
  quote: string;
  name: string;
}

const Quote: React.FC<QuoteProps> = ({ quote, name }) => {
  const isBigScreen = useMediaQuery({ query: "(min-width: 992px)" });
  const backgroundColor = isBigScreen ? "#fff" : "#000";

  return (
    <Layout style={{ background: backgroundColor }}>
      <Row justify="center" align="middle" gutter={[0, 0]}>
        <div>
          <Col span={24}>
            <div className="ms-quote">
              <q>{quote}</q>
            </div>
          </Col>
          <Col span={24}>
            <span className="ms-quote__name" style={{ color: "#fff" }}>
              {name}
            </span>
          </Col>
        </div>
      </Row>
    </Layout>
  );
};

export default Quote;
