import React from "react";
import Headline from "../headline/Headline";
import Text from "../text/Text";
import { Layout, Row, Col, Result } from "antd";
import { MotionConfig, motion } from "framer-motion";
import PrimaryButton from "../button/Button";
import Logo from "../logo/Logo";
import AnimatedLogo from "../logo/AnimatedLogo";

const NotFound: React.FC = () => {
  const animation = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };
  return (
    <MotionConfig transition={{ duration: 0.6, delay: 0.5 }}>
      <motion.div
        style={{ minHeight: "100vh" }}
        variants={animation}
        initial="hidden"
        animate="show"
      >
        <Layout>
          <Row justify='space-between'>
            <div></div>
            <Col>
              <div style={{ height: "128px" }}>
                <div style={{ lineHeight: "128px" }}>
                  <Logo
                    isOpen={false}
                    toggleState={() => {}}
                    currentLanguage="en"
                    mediumBlogActive={false}
                    href="/"
                  />
                </div>
              </div>
            </Col>
            <div></div>
          </Row>
        </Layout>
        <Layout
          className="ms-content"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
          }}
        >
          <Row justify="center" align="top">
            <Col span={24}>
              <Headline title="404" subtitle="" level="h1" align="center" />
            </Col>
            <Col span={24}>
              <Text
                text="The page you are looking for has not been found!"
                align="center"
              />
              <Text
                text="Please choose a page from the navigation."
                align="center"
              />
            </Col>
            <Col>
              <PrimaryButton
                primaryText="home"
                secondaryText=""
                href="/"
                onClick={() => {}}
                large
              />
            </Col>
          </Row>
        </Layout>
      </motion.div>
    </MotionConfig>
  );
};

export default NotFound;
