import React, { Component, useState } from 'react';
import { Slant as Hamburger } from 'hamburger-react';

export interface MenuState {
    href: string;
    isOpen: boolean;
    toggleState: () => void;
    currentLanguage: string;
    mediumBlogActive: boolean;
}

class HamburgerIcon extends Component<MenuState> {
    render() {
        return (
            <Hamburger
            color={this.props.isOpen ? 'white' : 'black' }
            toggled={this.props.isOpen} 
            toggle={this.props.toggleState} 
            size={24}
            distance='sm' 
            hideOutline={true}
            easing='ease-in-out'
            duration={0.3}
            label="Show menu"
            onToggle={toggled => {
                if (toggled) {
                    document.body.classList.add("ms-mobile-menu-body--active");
                    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', "#000");
                } else {
                    document.body.classList.remove("ms-mobile-menu-body--active");
                    setTimeout(function() {
                        document.querySelector('meta[name="theme-color"]')?.setAttribute('content', "#FFF");
                    }, 200);
                }
              }}/>
        );
    }
}

export default HamburgerIcon;