import React from "react";
import MediaQuery from "react-responsive";
import './image.css';

export interface ImageProps {
    mobileSrc: string;
    desktopSrc: string;
    alt: string;
}

const Image: React.FC<ImageProps> = ({ mobileSrc, desktopSrc, alt }) => {
    return (
        <div className="ms-inner-content">
            <MediaQuery maxWidth={991}>
                <img className="ms-image" src={mobileSrc} alt={alt} title={alt} />
            </MediaQuery>
            <MediaQuery minWidth={992}>
                <img className="ms-image" src={desktopSrc} alt={alt} title={alt} />
            </MediaQuery>
        </div>
    );
};

export default Image;