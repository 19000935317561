import React from "react";
import SVG from "react-inlinesvg";
import RightArrow from "../../assets/arrow-right.svg";
import "./arrowbutton.css";

interface ArrowButtonProps {
  disabled: boolean;
}

const ArrowButton: React.FC<ArrowButtonProps> = ({disabled}) => {
  return (
    <button className="ms-arrow-button" type="submit" disabled={disabled}>
      <SVG
          style={{ width: "2rem", height: "2rem" }}
          className="ms-logo"
          src={RightArrow}
          title="Right Arrow Button"
      />
    </button>
  );
};

export default ArrowButton;
