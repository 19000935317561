import React, { Component } from "react";
import SVG from "react-inlinesvg";
import { Row, Col, Layout } from "antd";
import logo from "../../ms-logo-partial.svg";
import "./logo.css";

interface LogoProps {
  href: string;
}

interface State {
  activeIndex: number;
  isHovered: boolean;
  hasCompletedInitialAnimation: boolean;
}

class AnimatedLogo extends Component<LogoProps, State> {
  private words = ["stylist", "personal shopper", "fashion advisor"];
  private timeoutId: number | null = null;

  constructor(props: LogoProps) {
    super(props);
    this.state = {
      activeIndex: 0,
      isHovered: false,
      hasCompletedInitialAnimation: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.startAnimationOnce();
    }, 1000);
  }

  startAnimationOnce = () => {
    this.timeoutId = window.setInterval(() => {
      this.setState((prevState) => {
        let newIndex = prevState.activeIndex + 1;
        // If it completes a cycle and hasn't completed the initial animation
        if (newIndex > this.words.length - 1) {
          if (!prevState.hasCompletedInitialAnimation) {
            clearInterval(this.timeoutId!);
            return {
              activeIndex: 0,
              hasCompletedInitialAnimation: true,
            };
          }
        }

        return {
          activeIndex: newIndex,
          hasCompletedInitialAnimation: prevState.hasCompletedInitialAnimation,
        };
      });
    }, 3000);
  };

  startAnimationLoop = () => {
    if (this.state.hasCompletedInitialAnimation) {
      this.timeoutId = window.setInterval(() => {
        this.setState((prevState) => {
          let newIndex = prevState.activeIndex + 1;
          return {
            activeIndex:
              newIndex > this.words.length - 1 ? 0 : prevState.activeIndex + 1,
          };
        });
      }, 3000);
    }
  };

  stopAnimationLoop = () => {
    if (this.timeoutId) {
      this.setState((prevState) => ({
        activeIndex: 0,
        hasCompletedInitialAnimation: true,
      }));
      clearInterval(this.timeoutId);
    }
  };

  render() {
    const { activeIndex } = this.state;
    return (
      <a
        href={this.props.href}
        style={{ color: "#000", position: "relative" }}
        onMouseEnter={this.startAnimationLoop}
        onMouseLeave={this.stopAnimationLoop}
      >
        <Layout>
          <Row gutter={0} style={{ margin: "0 auto" }}>
            <Col style={{ display: "inherit" }}>
              <SVG
                style={{ width: "auto", height: "3.5rem" }}
                className="ms-logo"
                src={logo}
                title=""
              />
            </Col>
            <Col>
              <div
                className="ms-logo__words"
                style={{
                  position: "absolute",
                  fontSize: "1.25rem",
                  fontFamily: "Open Sans",
                  fontWeight: 300,
                  display: "block",
                  letterSpacing: "-0.09rem",
                  height: "2rem",
                  width: "10rem",
                  top: "-1.5rem",
                  left: "2px",
                }}
              >
                <div className="ms-logo__container">
                  {this.words.map((word, index) => (
                    <span
                      key={word}
                      className={`ms-logo__word${
                        activeIndex === index ? " active" : ""
                      }`}
                      style={{
                        transform: `rotateX(${(index - activeIndex) * 90}deg)`,
                      }}
                    >
                      {word}
                    </span>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </Layout>
      </a>
    );
  }
}

export default AnimatedLogo;
