import React, { Component } from "react";
import { Dropdown, Row, Col, Typography } from "antd";
import Icon from "../../icon/Icon";
// Styles
import arrow from "../../../assets/arrow.svg";
import "./menuitem.css";

interface Props {
  dropdown: boolean;
  subItems: string[];
  subItemsSlugs: string[];
  name: string;
  slug: string;
  url?: string;
}

interface State {
  submenuActive: boolean;
  menuHeight: string;
}

class MenuItem extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      submenuActive: true,
      menuHeight: "3rem",
    };
  }
  render() {
    this.toggleMenu();
    return <>{this.menuItem()}</>;
  }

  menuItem(): JSX.Element {
    if (this.state.submenuActive && this.props.dropdown) {
      return (
        <li style={{ height: "auto" }}>
          <Row
            style={{ cursor: "pointer" }}
            gutter={10}
          >
            <Col>{this.item()}</Col>
            <Col>{this.arrow()}</Col>
          </Row>
        </li>
      );
    } else if (this.props.dropdown) {
        return (
            <li style={{ height: `${this.state.menuHeight}` }}>
              <Row
                style={{ cursor: "pointer" }}
                gutter={10}
              >
                <Col>{this.item()}</Col>
                <Col>{this.arrow()}</Col>
              </Row>
            </li>
          );
    } else {
        return (
            <li style={{ maxHeight: `${this.state.menuHeight}` }}>
              <Row
                style={{ cursor: "pointer" }}
                gutter={10}
              >
                <Col>{this.item()}</Col>
                <Col>{this.arrow()}</Col>
              </Row>
            </li>
          );
    }
  }

  item(): JSX.Element {
    let words: string[] = this.props.name.split(" ");
    if (words.length > 1) {
      let secondIndex = words.length - 1;
      let firstPart: string = words.slice(0, secondIndex).join(" ");
      let secondPart: string = words[secondIndex];
      if (this.props.dropdown) {
        return (
          <>
            <Typography.Text className="ms-menu__item" onClick={() => this.handleClick}>
              <span className="ms-menu__item-primary--mobile">
                {firstPart}{" "}
              </span>
              <span className="ms-menu__item-secondary--mobile">
                {secondPart}
              </span>
            </Typography.Text>
            {this.subItems()}
          </>
        );
      }
      if (this.props.url) {
        return (
          <a href={`${this.props.url}`} target="_blank">
            <Typography.Text className="ms-menu__item">
              <span className="ms-menu__item-primary--mobile">{firstPart} </span>
              <span className="ms-menu__item-secondary--mobile">
                {secondPart}
              </span>
            </Typography.Text>
          </a>
        );
      }
      return (
        <a href={`/${this.props.slug}`}>
          <Typography.Text className="ms-menu__item">
            <span className="ms-menu__item-primary--mobile">{firstPart} </span>
            <span className="ms-menu__item-secondary--mobile">
              {secondPart}
            </span>
          </Typography.Text>
        </a>
      );
    }
    return (
      <a href={`/${this.props.slug}`}>
        <Typography.Text className="ms-menu__item">
          {this.props.name}
        </Typography.Text>
      </a>
    );
  }

  subItems(): JSX.Element {
    if (this.props.dropdown) {
      let items = [];
      for (let i = 0; i < this.props.subItems.length; i++) {
        items.push(
          <li key={i} style={{ minHeight: '2.5rem' }}>
            <a href={`/${this.props.subItemsSlugs[i]}`} style={{ display: 'inline-block' }}>
              <Typography.Text className="ms-menu__item__sub-item">
                {this.props.subItems[i]}
              </Typography.Text>
            </a>
          </li>
        );
      }
      return <ul className="ms-mobile-menu__sub-menu" style={{ height: '12.5rem', paddingBottom: `${this.state.submenuActive ? '2rem' : '0'}` }}>{items}</ul>;
    }
    return <></>;
  }

  arrow(): JSX.Element {
    if (this.props.dropdown && this.props.subItems.length > 0) {
      return (
        <Icon
          className={`ms-mobile-menu__arrow ${
            this.state.submenuActive ? "ms-mobile-menu__arrow--active" : ""
          }`}
          color="#ffffff"
          src={arrow}
          height={10}
          title="Arrow icon"
        />
      );
    }
    return <></>;
  }

  handleClick = () => {
    this.setState(
      (prevState) => ({ submenuActive: !prevState.submenuActive }),
      () => {
        if (!this.state.submenuActive) {
          this.setState({ menuHeight: "auto" });
          setTimeout(() => {
            this.setState({ menuHeight: "3rem" });
          }, 300);
        } else {
          this.setState({ menuHeight: "3rem" });
        }
        this.toggleMenu();
      }
    );
  };

  toggleMenu = () => {
    let submenu = document.querySelector(
      ".ms-mobile-menu__sub-menu"
    ) as HTMLElement | null;
    if (submenu) {
      if (!this.state.submenuActive) {
        submenu.style.maxHeight = "0px";
      } else {
        submenu.style.maxHeight = submenu.scrollHeight + "px";
      }
    }
  };
}

export default MenuItem;
