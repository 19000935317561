import React from "react";
import {
  Form,
  Input,
  Checkbox,
  ConfigProvider,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import './formfield.css';

export enum FormFieldType {
  Text,
  Phone,
  Email,
  Checkbox,
  TextArea,
}

interface FormFieldProps {
  type: FormFieldType;
  name: string;
  label: string;
  showLabel?: boolean;
  required: boolean;
}

const FormField: React.FC<FormFieldProps> = ({ type, name, label, showLabel = false, required }) => {
  const input = () => {
    switch (type) {
      case FormFieldType.Text:
        return <Input placeholder={label} className='ms-form-field' autoComplete="off"/>;
      case FormFieldType.Phone:
        return <Input placeholder={label} className='ms-form-field' autoComplete="phone"/>;
      case FormFieldType.Email:
        return <Input placeholder={label} className='ms-form-field' autoComplete="email"/>;
      case FormFieldType.Checkbox:
        return <Checkbox className="ms-form-checkbox">{label}</Checkbox>;
      case FormFieldType.TextArea:
        return <TextArea placeholder={''} className='ms-form-textarea' autoSize={{ minRows: 10, maxRows: 10 }} />;
    }
  };

  const requiredMessage = () => {
    switch (type) {
      case FormFieldType.Text:
        return "Please enter the text.";
      case FormFieldType.Phone:
        return "Please enter your phone number.";
      case FormFieldType.Email:
        return "Please enter your email address.";
      case FormFieldType.Checkbox:
        return "Please select an option.";
      case FormFieldType.TextArea:
        return "Please enter a message.";
    }
  };

  const dataType = () => {
    switch (type) {
      case FormFieldType.Text:
        return "string";
      case FormFieldType.Phone:
        return "string";
      case FormFieldType.Email:
        return "email";
      case FormFieldType.Checkbox:
        return "boolean";
      case FormFieldType.TextArea:
        return "string";
    }
  };

  const field = () => {
    if (showLabel) {
      return (
        <Form.Item name={name} label={label} rules={[{ required: required, message: requiredMessage(), type: dataType() }]}>
          {input()}
        </Form.Item>
      );
    }
    if (type == FormFieldType.Checkbox) {
      return (
        <Form.Item name={name} valuePropName="checked" rules={[{ required: required, message: requiredMessage(), type: dataType() }]}>
          {input()}
        </Form.Item>
      );
    }
    return (
      <Form.Item name={name} rules={[{ required: required, message: requiredMessage(), type: dataType() }]}>
        {input()}
      </Form.Item>
    );
  };

  return (
    <ConfigProvider
          theme={{
            components: {
              Input: {
                /* here is your component tokens */
                activeBorderColor: '#000',
                hoverBorderColor: '#000'
              },
            },
          }}>
          {field()}
      </ConfigProvider>
  );
};

export default FormField;
